import { ReactTyped } from "react-typed"
import { 
  Box,
  Typography
} from "@mui/material"

const Header = () => {
  return (
    <Box sx={{
      borderBottomColor: "#000000",
      borderBottomWidth: 5,
      borderBottomStyle: "solid",
      paddingLeft: 2,
      paddingBottom: 0.7
    }}>
      <Typography variant="h2" sx={{
        fontFamily: "Paytone One"
      }}>
        <ReactTyped
          strings={[
            "<i>Latent</i> Labs",
            "Latent Labs",
            "Latent <i>Labs </i>"
          ]}
          typeSpeed={50}
          startDelay={50}
          backSpeed={40}
          showCursor
          smartBackspace
          loop
        />
      </Typography>
      <Typography variant="h6" sx={{
        fontFamily: "Space Mono",
        paddingLeft: 0.3
      }}>
        Building a new web
      </Typography>
    </Box>
  )
}

export default Header