import { Box, Typography } from "@mui/material"

interface Props {
  label?: string
}

const Badge = ({ label }: Props) => {
  return (
    <Box 
      display="inline-block" 
      marginRight={0.5} 
      marginBottom={0.5} 
      padding={0.3} 
      paddingRight={1.3} 
      paddingLeft={1.3} 
      borderRadius={1} 
      sx={{
        backgroundColor: "#000000"
      }}
    >
      <Typography 
        fontFamily="Space Mono" 
        color="#E0D3D3"
      >
        {label}
      </Typography>
    </Box>
  )
}

export default Badge