import { Box } from "@mui/material"
import Marquee from "react-fast-marquee"

let style = {
  display: "flex", 
  justifyContent: "center",
  paddingTop: "5px",
  paddingRight: "40px",
  paddingBottom: "5px"
}

const ImageTicker = () => {
  return (
    <Marquee
      direction={"left"}
      speed={25}
      gradient={false}
      pauseOnHover={true}
    >
      <Box sx={style}> 
        <img src="/media/yzr2m.png" alt="" height="70px"></img>
      </Box>
      <Box sx={style}> 
        <img src="/media/ehyw6.png" alt="" height="70px"></img>
      </Box>
      <Box sx={style}> 
        <img src="/media/xjjz2.png" alt="" height="70px"></img>
      </Box>
      <Box sx={style}> 
        <img src="/media/8ad0x.png" alt="" height="70px"></img>
      </Box>
      <Box sx={style}> 
        <img src="/media/9toue.png" alt="" height="70px"></img>
      </Box>
      <Box sx={style}> 
        <img src="/media/z8uc3.png" alt="" height="70px"></img>
      </Box>
    </Marquee>
  )
}

export default ImageTicker