import { Box } from "@mui/material"

interface Props {
  ml?: number,
  mt?: number,
  mr?: number,
  mb?: number,
  pl?: number,
  pt?: number,
  pr?: number,
  pb?: number,
  bl?: number,
  bt?: number,
  br?: number,
  bb?: number,
  children: React.ReactNode
}

const Space = ({
  ml=0,
  mt=0,
  mr=0,
  mb=0,
  pl=0,
  pt=0,
  pr=0,
  pb=0,
  bl=0,
  bt=0,
  br=0,
  bb=0,
  children 
}: Props) => {
  return (
    <Box sx={{
      marginLeft: ml,
      marginTop: mt,
      marginRight: mr,
      marginBottom: mb,
      paddingLeft: pl,
      paddingTop: pt,
      paddingRight: pr,
      paddingBottom: pb,
      borderColor: "#000000",
      borderStyle: "solid",
      borderLeftWidth: bl,
      borderTopWidth: bt,
      borderRightWidth: br,
      borderBottomWidth: bb
    }}>
      {children}
    </Box>
  )
}

export default Space